var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tdTjBhk",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tdTjBhkForm",
          attrs: {
            model: _vm.tdTjBhkForm,
            rules: _vm.tdTjBhkFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "体检机构编号", prop: "BHKORGAN_CODE" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder:
                            "请输入体检机构编号，由职业卫生平台提供的机构编码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.bhkorganCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "bhkorganCode", $$v)
                          },
                          expression: "tdTjBhkForm.bhkorganCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "体检编号", prop: "BHK_CODE" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入体检编号，机构内需唯一",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.bhkCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "bhkCode", $$v)
                          },
                          expression: "tdTjBhkForm.bhkCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "社会信用代码",
                        prop: "INSTITUTION_CODE",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入社会信用代码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.institutionCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "institutionCode", $$v)
                          },
                          expression: "tdTjBhkForm.institutionCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "企业名称", prop: "CRPT_NAME" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入企业名称",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.crptName,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "crptName", $$v)
                          },
                          expression: "tdTjBhkForm.crptName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "企业注册地址", prop: "CRPT_ADDR" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入企业注册地址",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.crptAddr,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "crptAddr", $$v)
                          },
                          expression: "tdTjBhkForm.crptAddr",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "人员姓名", prop: "PERSON_NAME" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入人员姓名",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.personName,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "personName", $$v)
                          },
                          expression: "tdTjBhkForm.personName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "性别", prop: "SEX" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入性别",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.sex,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "sex", $$v)
                          },
                          expression: "tdTjBhkForm.sex",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "证件号码", prop: "IDC" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入证件号码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.idc,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "idc", $$v)
                          },
                          expression: "tdTjBhkForm.idc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "出生日期", prop: "BRTH" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入出生日期",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.brth,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "brth", $$v)
                          },
                          expression: "tdTjBhkForm.brth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "年龄", prop: "AGE" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入年龄",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.age,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "age", $$v)
                          },
                          expression: "tdTjBhkForm.age",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "婚否", prop: "ISXMRD" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入婚否",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.isxmrd,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "isxmrd", $$v)
                          },
                          expression: "tdTjBhkForm.isxmrd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "人员联系电话", prop: "LNKTEL" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入人员联系电话",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.lnktel,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "lnktel", $$v)
                          },
                          expression: "tdTjBhkForm.lnktel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "总工龄年数", prop: "WRKLNT" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入总工龄年数",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.wrklnt,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "wrklnt", $$v)
                          },
                          expression: "tdTjBhkForm.wrklnt",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "总工龄月数", prop: "WRKLNTMONTH" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder:
                            "请输入总工龄月数,不能超过12,当总工龄年数不为空时，该字段必填",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.wrklntmonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "wrklntmonth", $$v)
                          },
                          expression: "tdTjBhkForm.wrklntmonth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "接害工龄年数", prop: "TCHBADRSNTIM" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入接害工龄年数,不能超过12",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.tchbadrsntim,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "tchbadrsntim", $$v)
                          },
                          expression: "tdTjBhkForm.tchbadrsntim",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: { label: "接害工龄月数", prop: "TCHBADRSNMONTH" },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入接害工龄月数",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.tchbadrsnmonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "tchbadrsnmonth", $$v)
                          },
                          expression: "tdTjBhkForm.tchbadrsnmonth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "工种其他名称", prop: "WORK_NAME" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入工种其他名称",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.workName,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "workName", $$v)
                          },
                          expression: "tdTjBhkForm.workName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "在岗状态编码", prop: "ONGUARD_STATE" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入在岗状态编码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.onguardState,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "onguardState", $$v)
                          },
                          expression: "tdTjBhkForm.onguardState",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "体检日期", prop: "BHK_DATE" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入体检日期",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.bhkDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "bhkDate", $$v)
                          },
                          expression: "tdTjBhkForm.bhkDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "体检结果", prop: "BHKRST" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入体检结果",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.bhkrst,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "bhkrst", $$v)
                          },
                          expression: "tdTjBhkForm.bhkrst",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "主检建议", prop: "MHKADV" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入主检建议",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.mhkadv,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "mhkadv", $$v)
                          },
                          expression: "tdTjBhkForm.mhkadv",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "体检结论", prop: "VERDICT" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入体检结论",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.verdict,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "verdict", $$v)
                          },
                          expression: "tdTjBhkForm.verdict",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "主检医师", prop: "MHKDCT" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入主检医师",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.mhkdct,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "mhkdct", $$v)
                          },
                          expression: "tdTjBhkForm.mhkdct",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "体检类型编码", prop: "BHK_TYPE" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入体检类型编码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.bhkType,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "bhkType", $$v)
                          },
                          expression: "tdTjBhkForm.bhkType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "主检判定日期", prop: "JDGDAT" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入主检判定日期",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.jdgdat,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "jdgdat", $$v)
                          },
                          expression: "tdTjBhkForm.jdgdat",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "接害因素", prop: "BADRSN" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入接害因素",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.badrsn,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "badrsn", $$v)
                          },
                          expression: "tdTjBhkForm.badrsn",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "是否为复检", prop: "IF_RHK" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入是否为复检",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.ifRhk,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "ifRhk", $$v)
                          },
                          expression: "tdTjBhkForm.ifRhk",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "复检对应的上次体检编号",
                        prop: "LAST_BHK_CODE",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入复检对应的上次体检编号",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.lastBhkCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "lastBhkCode", $$v)
                          },
                          expression: "tdTjBhkForm.lastBhkCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "身份证件类型代码",
                        prop: "ID_CARD_TYPE_CODE",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入身份证件类型代码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.idCardTypeCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "idCardTypeCode", $$v)
                          },
                          expression: "tdTjBhkForm.idCardTypeCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "工种代码", prop: "WORK_TYPE_CODE" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入工种代码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.workTypeCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "workTypeCode", $$v)
                          },
                          expression: "tdTjBhkForm.workTypeCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: { label: "开始接害日期", prop: "HARM_START_DATE" },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入开始接害日期",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.harmStartDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "harmStartDate", $$v)
                          },
                          expression: "tdTjBhkForm.harmStartDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "监测类型", prop: "JC_TYPE" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入监测类型",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.jcType,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "jcType", $$v)
                          },
                          expression: "tdTjBhkForm.jcType",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: { label: "报告打印日期", prop: "RPT_PRINT_DATE" },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入报告打印日期",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.rptPrintDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "rptPrintDate", $$v)
                          },
                          expression: "tdTjBhkForm.rptPrintDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "用工单位社会信用代码",
                        prop: "CREDIT_CODE_EMP",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入用工单位社会信用代码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.creditCodeEmp,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "creditCodeEmp", $$v)
                          },
                          expression: "tdTjBhkForm.creditCodeEmp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "用工单位名称", prop: "CRPT_NAME_EMP" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入用工单位名称",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.crptNameEmp,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "crptNameEmp", $$v)
                          },
                          expression: "tdTjBhkForm.crptNameEmp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "用工单位行业类别编码",
                        prop: "INDUS_TYPE_CODE_EMP",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入用工单位行业类别编码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.indusTypeCodeEmp,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "indusTypeCodeEmp", $$v)
                          },
                          expression: "tdTjBhkForm.indusTypeCodeEmp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "用工单位经济类型编码",
                        prop: "ECONOMY_CODE_EMP",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入用工单位经济类型编码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.economyCodeEmp,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "economyCodeEmp", $$v)
                          },
                          expression: "tdTjBhkForm.economyCodeEmp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "用工单位企业规模编码",
                        prop: "CRPT_SIZE_CODE_EMP",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入用工单位企业规模编码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.crptSizeCodeEmp,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "crptSizeCodeEmp", $$v)
                          },
                          expression: "tdTjBhkForm.crptSizeCodeEmp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    {
                      attrs: {
                        label: "用工单位所属地区编码",
                        prop: "ZONE_CODE_EMP",
                      },
                    },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入用工单位所属地区编码",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.zoneCodeEmp,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "zoneCodeEmp", $$v)
                          },
                          expression: "tdTjBhkForm.zoneCodeEmp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: "12" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "是否上传标志", prop: "flag" } },
                    [
                      _c("Input", {
                        attrs: {
                          disabled: _vm.disabled,
                          type: "text",
                          maxlength: 50,
                          placeholder: "请输入是否上传标志",
                        },
                        model: {
                          value: _vm.tdTjBhkForm.flag,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "flag", $$v)
                          },
                          expression: "tdTjBhkForm.flag",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "错误信息", prop: "errorInfo" } },
                    [
                      _c("Input", {
                        attrs: { type: "textarea", readonly: "", rows: 6 },
                        model: {
                          value: _vm.tdTjBhkForm.errorInfo,
                          callback: function ($$v) {
                            _vm.$set(_vm.tdTjBhkForm, "errorInfo", $$v)
                          },
                          expression: "tdTjBhkForm.errorInfo",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }